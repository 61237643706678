import React from "react";
import { graphql } from "gatsby";

import Page from "../templates/Page";

const NotFoundPage = ({ data }) => {
  return <Page data={data} />;
};

export const pageQuery = graphql`
  query {
    settings: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "settings" } } }
    ) {
      edges {
        node {
          ...settingsFragment
        }
      }
    }
    page: markdownRemark(frontmatter: { path: { eq: "/404" } }) {
      ...pageFragment
    }
  }
`;

export default NotFoundPage;